var reSwiper = new Swiper(".re-swiper", {
  speed: 1000,
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },
  pagination: {
    el: ".swiper-pagination",
    type: "fraction"
  },
  navigation: {
    nextEl: ".re-swiper-navigation-button--next",
    prevEl: ".re-swiper-navigation-button--prev"
  },
  on: { // イベントを登録する
    slideChange: (a, b) => {
      // console.log("change")
      // console.log($(this))
      // console.log(a)
      // console.log(b)
      updatePaginationNumberStyle();
    },
  },
});

var updatePaginationNumberStyle = function () {
  $('.re-swiper-pagination').each(function () {
    const currentNum = $(this).siblings('.swiper-pagination').find('.swiper-pagination-current').text();
    $(this).find('.re-swiper-pagination__current').text(getDoubleDigestNumber(currentNum));

    const totalNum = $(this).siblings('.swiper-pagination').find('.swiper-pagination-total').text();
    $(this).find('.re-swiper-pagination__total').text(getDoubleDigestNumber(totalNum));
  });
}

var getDoubleDigestNumber = function (e) {
  return ("0" + e).slice(-2);
}

$(function () {
  updatePaginationNumberStyle();
});