const sliderSettings = {
  pagerSpeed: 5000,
  animation_duration: 1000,
  sliderSelector: '.commonSlider',
  cssVarName: '--pager-speed',
};

const commonSlider = new Swiper(sliderSettings.sliderSelector, {
  autoplay: {
    delay: sliderSettings.pagerSpeed,
    disableOnInteraction: false,
  },
  loop: true,
  effect: 'slide',
  speed: sliderSettings.animation_duration,
  pagination: {
    el: '.commonSlider-pagination',
    clickable: true,
  },
});

if (typeof commonSlider.el === 'object') {
  commonSlider.el.style.setProperty(sliderSettings.cssVarName, (sliderSettings.pagerSpeed + 800) + 'ms');
}
